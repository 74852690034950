<template>
  <layout width="540">
    <template slot="header">
      About program
    </template>
    <template>
      <form-subheader title="Application" />
      <form-item-value title="PixelMonitor version" :value="localVersion" />
      <form-item-value title="PixelCore version" :value="version" />
      <form-item-value title="PixelCore timezone" :value="timezone" />
      <form-subheader title="About us" />
      <div class="text-body-1 flex-shrink-0" v-html="aboutInfo" />
    </template>

    <template slot="footer">
      <v-spacer />
      <v-btn color="primary" text @click.stop="$emit('close')">
        OK
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { onMounted } from 'vue-demi';
import { getVersion, getTimezone } from '@/modules/about/getVersion';
import { ref } from '@vue/composition-api';
import appVersion from 'raw-loader!../../../VERSION';
import { prepareObject } from '@/provider/utils';
import { objectService } from '@/modules/common/api';
import { useAuth } from '@/modules/auth/api';

export default {
  name: 'About',
  components: {
    Layout
  },
  setup() {
    let version = ref('Loading...');
    let timezone = ref('Loading...');
    let localVersion = ref('Loading...');
    let aboutInfo = ref('<div>Loading...</div>');
    const { getUserProfileId } = useAuth();

    const load = async () => {
      const id = await getUserProfileId();

      aboutInfo.value =
        prepareObject(
          await objectService.fetch(id, { fetchPolicy: 'network-only' })
        )?.aboutInfo?.value?.content || '<div></div>';
    };
    onMounted(() => {
      load();

      localVersion.value = appVersion;
      getVersion().then(res => {
        version.value = res.short;
      });
      getTimezone().then(res => {
        timezone.value = res;
      });
    });

    return {
      version,
      timezone,
      localVersion,
      aboutInfo
    };
  }
};
</script>

<style lang="sass"></style>
